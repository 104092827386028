import React from "react";
import { Layout } from "antd";
const { Content } = Layout;

class FeaturesContentPage extends React.Component {
  state = {};
  render() {
    return (
      <Content>
        <div className="content">
          <h1>DayViewer Feature Highlights</h1>
          <p>More information on DayViewer core features and how they work</p>
          <p>
            Open the side menu to see more from the DayViewer feature
            highlights.
          </p>
          <br />
          <br />
          <div
            style={{
              textAlign: "center",
              padding: 20,
            }}
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/mrJiY4srufM"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </Content>
    );
  }
}

export default FeaturesContentPage;

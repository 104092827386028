import React from "react";
import { Link } from "gatsby";
import { Layout, Menu } from "antd";
import { BookOutlined } from "@ant-design/icons";
const { Sider } = Layout;

export default class featuresSider extends React.Component {
  render() {
    return (
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <Link to={`/features/`}>
          <h2 style={{ textAlign: "center", marginTop: 20 }}>
            <BookOutlined /> Features
          </h2>
        </Link>
        <div>
          <Menu theme="light">
            {/* <Menu.Item key="1">
              <Link to="/features/online-calendar">Online Calendar</Link>
            </Menu.Item> */}
            <Menu.Item key="2">
              <Link to="/features/week-planner">Week Planner</Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/features/task-management">Task Management</Link>
            </Menu.Item>
            {/* <Menu.Item key="4">
              <Link to="/features/boards">Boards</Link>
            </Menu.Item> */}
            <Menu.Item>More Soon...</Menu.Item>
          </Menu>
        </div>
      </Sider>
    );
  }
}
